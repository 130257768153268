/* -Floating call-to-aciton button
---------------------------------------------------------------------------- */
.floating-cta {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: $z-floating-cta;

  @include media-min(rem(600px)) {
    @include button-large;

    bottom: 20px;
    right: 20px;
  }
}
