/* -News listing pages
---------------------------------------------------------------------------- */
.news-list {
  @include section-margin;

  .news-item:not(:last-child) {
    @include section-margin-bottom;
  }
}
.news-list__bottom {
  @include section-margin-top(2);
}

@include media-between(rem(700px), $breakpoint-news-grid-columns - rem(1px)) {
  .news-list__top {
    .news-popular {
      @include grid-container;
    }
    .news-popular__articles,
    .news-popular__tags {
      @include grid-item('1 / 2');
    }
  }
}

@include media-between(
  $breakpoint-news-item-vertical,
  $breakpoint-news-grid-columns - rem(1px)
) {
  .news-list__all-extra {
    @include grid-container;

    > .news-item {
      @include grid-item('1 / 2');
    }
  }
}
