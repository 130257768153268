/* -Company block listing companies
-----------------------------------------------------------------------------*/
.companies-block {
  @include section-padding-bottom;
}
.companies-block__content {
  @include section-padding;
  @include type-size('sub');

  text-align: center;
}

/* ------------------- List of company blocks ------------------- */
.companies-block__list {
  @include grid-container;
  @include grid-pull-bottom;

  display: flex;
  flex-wrap: wrap;
}
.companies-block__list--count-1,
.companies-block__list--count-2 {
  justify-content: center;
}

.companies-block__list-item {
  @include grid-item('full');
  @include grid-margin-bottom;

  display: flex;
  justify-content: center;

  @include media-min(rem(480px)) {
    @include grid-item-size('1 / 2');
  }
  @include media-min(rem(950px)) {
    @include grid-item-size('1 / 3');
  }
}
