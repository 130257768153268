/* -'Image and content' block
-----------------------------------------------------------------------------*/
$image-content-columns-breakpoint: 750px;
$image-content-gutter: 80px;
$image-content-gutter-small: $image-content-gutter * 0.5;

.image-content-block {
  // Use large size on smaller screens as well.
  margin-top: $section-spacing;
  margin-bottom: $section-spacing;

  &:first-child {
    @include section-margin-top(2);
  }
  &:last-child {
    @include section-margin-bottom(2);
  }
}

.image-content-block-image {
  margin-bottom: 1.5em;

  img {
    @include rounded('medium');

    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.image-content-block-content {
  @include type-size('sub');
}

// Go to two columns
@include media-min(rem($image-content-columns-breakpoint)) {
  .image-content-block-inner {
    display: flex;
  }
  .image-content-block:nth-child(2n + 1) {
    .image-content-block-image {
      margin-right: $image-content-gutter-small;
    }
  }
  .image-content-block:nth-child(2n) {
    .image-content-block-inner {
      flex-direction: row-reverse;
    }
    .image-content-block-image {
      margin-left: $image-content-gutter-small;
    }
  }
  .image-content-block-image {
    flex-shrink: 0;
    margin-bottom: 0;
    width: 35%;
    max-width: 400px;
    img {
      object-fit: fill;
      height: auto;
      width: auto;
    }
  }
  .image-content-block-content {
    flex-grow: 1;
  }
}

@include media-min(rem($image-content-columns-breakpoint + 200px)) {
  .image-content-block:nth-child(2n + 1) {
    .image-content-block-image {
      margin-right: $image-content-gutter;
    }
  }
  .image-content-block:nth-child(2n) {
    .image-content-block-image {
      margin-left: $image-content-gutter;
    }
  }
}
