/* -Testimonial component - block of image, quote text and name of author
-----------------------------------------------------------------------------*/
$testimonials-columns-breakpoint: rem(700px);

.testimonials-block {
  @include section-margin;
}
.testimonial-item {
  @include section-padding;

  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-min($testimonials-columns-breakpoint) {
    flex-direction: row;
  }
}

.testimonial-item__image {
  max-width: 160px;
  margin-bottom: 20px;
  border-radius: 50%;
  overflow: hidden;

  @include media-min(rem(800px)) {
    flex-shrink: 0;
    width: 35%;
    max-width: 200px;
  }

  @include media-min(rem(1800px)) {
    max-width: 250px;
  }

  .section-block__inner--wide & {
    @include media-min(rem(850px)) {
      max-width: 250px;
    }

    @include media-min(rem(1000px)) {
      max-width: 280px;
    }
  }
}

.testimonial-item__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  transform: rotate(-2deg);
}

.testimonial-item__quote {
  margin-bottom: 20px;
  padding: 0;
  border: none;
  font-size: 16px;
  font-style: italic;
  font-family: $font-stack-main;
  font-weight: $font-weight-bold;

  @include media-min(rem(500px)) {
    font-size: rem(18px);
  }

  @include media-min(rem(1000px)) {
    font-size: rem(22px);
  }

  @include media-min(rem(1600px)) {
    font-size: rem(24px);
  }

  .section-block__inner--wide & {
    @include media-min(rem(850px)) {
      font-size: rem(22px);
    }
    @include media-min(rem(1000px)) {
      font-size: rem(24px);
    }
    @include media-min(rem(1300px)) {
      font-size: rem(28px);
    }
  }
}

.testimonial-item__name {
  margin: 0;
  font-size: 18px;
  font-style: italic;

  @include media-min(rem(500px)) {
    font-size: rem(20px);
  }

  @include media-min(rem(1000px)) {
    font-size: rem(24px);
  }
}

.testimonial-item:nth-child(2n + 1) {
  .testimonial-item__image {
    box-shadow: -5px 5px 0px 0px $color-purple-light;
  }

  @include media-min($testimonials-columns-breakpoint) {
    flex-direction: row-reverse;

    .testimonial-item__image {
      margin-left: 40px;
    }
  }

  .section-block__inner--wide & {
    .testimonial-item__image {
      @include media-min(rem(850px)) {
        margin-left: 80px;
      }
    }
  }
}
.testimonial-item:nth-child(2n) {
  .testimonial-item__image {
    box-shadow: 5px 5px 0px 0px $color-purple-light;

    @include media-min($testimonials-columns-breakpoint) {
      margin-right: 40px;
    }

    .section-block__inner--wide & {
      @include media-min(rem(850px)) {
        margin-right: 80px;
      }
    }
  }
}
