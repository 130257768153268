/* -Popular articles and tags
---------------------------------------------------------------------------- */
.news-popular__article-list {
  @include section-margin-bottom;

  list-style: none;
  margin-left: 0;
  counter-reset: popular-articles;

  > li {
    position: relative;
    margin-bottom: 2em;
    padding-left: 40px;
    counter-increment: popular-articles;

    &::before {
      @include h;
      @include h3;
      @include theme-rule(color, 'lightest-accessible-large');

      content: counter(popular-articles);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.news-popular__tag-list {
  list-style: none;
  margin: 0;

  li {
    display: inline-block;
    margin: 0 10px 10px 0;
  }
}
