/* -Brand logo
---------------------------------------------------------------------------- */
.logo {
  display: inline-block;
  max-width: 200px;
}

.logo--conference {
  max-width: 270px;
}
