/* -Search form
---------------------------------------------------------------------------- */
.search-form {
  display: flex;
  max-width: 400px;

  button {
    margin-left: 10px;
  }
}
