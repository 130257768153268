/* -Latest news block
---------------------------------------------------------------------------- */
.latest-news__items {
  @include grid-container;
  @include grid-pull-bottom;

  > .news-item {
    @include grid-item('full');
    @include grid-margin-bottom;

    @include media-min(rem(700px)) {
      @include grid-item-size('1 / 2');
    }
  }
}
