/* -Block for speakers
---------------------------------------------------------------------------- */
$speakers-list-gutter: rem(50px);
$speakers-list-gutter-tight: rem(25px);

.block-speakers {
  @include wrap-wide;
  @include section-margin;
}

.block-speakers__content {
  max-width: $wrap-width-thin;
}

.block-speakers__container {
  @include section-margin;
  @include grid-container($speakers-list-gutter, $speakers-list-gutter-tight);
  @include grid-pull-bottom($speakers-list-gutter, $speakers-list-gutter-tight);

  justify-content: center;

  .speaker-card {
    @include grid-item(
      '1 / 2',
      $speakers-list-gutter,
      $speakers-list-gutter-tight
    );
    @include grid-margin-bottom($speakers-list-gutter);
  }
}

// Medium screens
@include media-min(rem(850px)) {
  .block-speakers {
    .speaker-card {
      @include grid-item-size('1 / 3');
    }
  }
}

// Large screens
@include media-min(rem(1250px)) {
  .block-speakers {
    .speaker-card {
      @include grid-item-size('1 / 4');
    }
  }
}
