/* -Global bits and pieces
-----------------------------------------------------------------------------*/
// The theme-rule mixin does not work with root selector
:root {
  --focus-outline-color: #{get-theme-color('light', 'foreground')};
  &.theme-dark {
    --focus-outline-color: #{get-theme-color('dark', 'foreground')};
  }
}
.google-forms-embed {
  padding: 15px 0;
  max-width: rem(670px);
  border-radius: 12px;
  background-color: #fff;

  .section-block--center & {
    margin: auto;
  }

  iframe {
    width: 100%;
  }
}

// Has tabindex for functional skiplinks, outline isn't needed in this case.
#main[tabindex] {
  outline: 0;
}

// Class handled with JavaScript, which tries to take accessibility in mind.
// Sets hide-focus when a mouse is used and show focus when a keyboard is.
// Hide outlines for mouse users and make it more distinct for keyboard users.
a,
button,
input[type='submit'],
input[type='button'],
input[type='reset'],
[tabindex] {
  .hide-focus & {
    outline: 0 !important;
  }
  .show-focus &:focus {
    outline: 3px dotted var(--focus-outline-color);
    outline-offset: 1px;
  }
  // Override outline colors when necessary
  .show-focus .lightbox &:focus {
    outline-color: #fff;
  }
}

// Effectively disable all animations if the user doesn't want them
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-iteration-count: 1 !important;
    animation-duration: 0.001s !important;
    transition-duration: 0.001s !important;
  }
}

.error-page-content {
  margin: 80px 20px 0;
  min-height: 80vh;

  @include media-max($breakpoint-grid-tight) {
    margin: 40px 0;
  }
}
