/* -The main header area with logo and navigation
-----------------------------------------------------------------------------*/
$breakpoint-site-header-large: rem(1000px);
$site-header-padding-small: 10px;
$site-header-padding-large: 20px;

.site-header__main,
.site-header__secondary {
  @include theme-separator-bottom;

  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: $site-header-padding-small;

  @include media-min($breakpoint-site-header-large) {
    padding: $site-header-padding-large;
  }
}

.site-header__logo {
  flex-shrink: 0;
}

.site-header__menu {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;

  @include media-min($breakpoint-menu-toggle + rem(1px)) {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
}

.header-lang-item {
  ul {
    border-left: 1px solid currentColor;
    list-style: none;
    margin: 0;

    &:empty {
      display: none;
    }
  }
  ul,
  li,
  a {
    height: 100%;
  }
  a {
    @include alt-font('semi');

    display: flex;
    align-items: center;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.header-lang-item__short {
  display: none !important;
}
.header-lang-item__full {
  display: block !important;
}

/* ------------------- Nav menus ------------------- */
.header-nav {
  margin-left: auto;

  a {
    @include alt-font('semi');

    text-transform: uppercase;

    &:hover,
    &:focus {
      @include theme-tint-shade-transparent(background-color, 0.1);
    }
  }
  .highlighted {
    > a {
      @include button-primary-colors;
    }
  }
  .current-item {
    > a {
      @include button-secondary-colors;
      background-image: none !important;
    }
  }

  @include media-min($breakpoint-site-header-large) {
    a {
      font-size: rem(22px);
    }
  }

  // Small screen only
  .sub-menu {
    display: none;
  }
}

.header-nav--secondary {
  a {
    font-size: rem(16px);

    @include media-min($breakpoint-site-header-large) {
      font-size: rem(18px);
    }
  }
}

/* ------------------- Large screen menu only ------------------- */
@include media-min($breakpoint-menu-toggle + rem(1px)) {
  .menu-toggle {
    display: none;
  }

  .header-lang-item {
    margin-top: 5px;
    margin-bottom: 5px;
    ul {
      margin-left: 15px;
    }
    a {
      padding: 4px 0 4px 12px;
      font-size: 12px;
    }
  }
  .header-nav {
    margin-left: auto;

    ul {
      text-align: right;
    }
    li {
      margin: 5px 0 5px 10px;
    }
    a {
      @include rounded('small');

      display: inline-block;
      padding: 5px 15px 2px;
    }
    .highlighted {
      a {
        @include button-primary-colors;
      }
    }
    .current-item,
    .current-item-ancestor {
      a {
        @include button-secondary-colors;
        background-image: none !important;
      }
    }
  }
  .header-nav--secondary {
    a {
      @include button-secondary-colors;
    }
    .current-item,
    .current-item-ancestor {
      > a {
        @include button-secondary-inverted-colors;
      }
    }
  }
}
@include media-min($breakpoint-site-header-large) {
  .header-lang-item {
    a {
      padding: 8px 0 8px 12px;
    }
  }
}

/* ------------------- Toggled menu only ------------------- */
@include media-max($breakpoint-menu-toggle) {
  .site-header__logo {
    padding-right: $site-header-padding-small;
  }
  .site-header__main {
    padding: $site-header-padding-small;
  }

  .header-nav {
    display: none;
  }
  .header-lang-item {
    ul {
      margin-left: 16px;
      height: 100%;

      a {
        @include alt-font('semi');

        display: flex;
        padding-left: 15px;
      }
    }
  }

  .header-lang-item__short {
    display: inline-block !important;
  }
  .header-lang-item__full {
    display: none !important;
  }

  // ---------- Menu toggle button ----------
  .menu-toggle {
    position: relative;
    padding-right: 35px;
  }
  .menu-toggle-icon {
    top: 50%;
    right: 15px;
    margin-top: -2px;

    &,
    &::before,
    &::after {
      position: absolute;
      width: 13px;
      height: 2px;
      background-color: currentColor;
      transition: background-color 0.15s ease;
    }
    &::before,
    &::after {
      content: '';
      display: block;
      left: 0;
      transition: transform 0.15s ease;
    }
    &::before {
      top: 0;
      transform: translateY(-5px);
    }
    &::after {
      bottom: 0;
      transform: translateY(5px);
    }
    .nav-open & {
      background-color: rgba(255, 255, 255, 0);

      &::before {
        transform: translateY(0) rotate(-225deg);
      }
      &::after {
        transform: translateY(0) rotate(225deg);
      }
    }
  }

  // ---------- The open menu ----------
  .header-nav--main {
    @include theme-separator-bottom;

    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    z-index: 11;

    .nav-open &,
    .sub-menu {
      display: block;
    }
    li {
      @include theme-separator-top;
      @include theme-background;

      display: block;
    }
    a {
      padding: 10px 15px 8px;
      font-size: rem(18px);
    }
    ul ul a {
      padding-left: 40px;
      font-size: rem(14px);
    }
    ul ul ul a {
      padding-left: 65px;
    }
  }
}

@include media-max(rem(500px)) {
  .site-header .logo {
    max-width: 130px;
  }
}
