/* -Breadcrumb links
---------------------------------------------------------------------------- */

// Prefer an even number for clean rounding
$breadcrumb-arrow-size: 6px;

.breadcrumb {
  font-size: rem(14px);

  li {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    &:not(:last-child) {
      margin-right: $breadcrumb-arrow-size * 4;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 100%;
        width: $breadcrumb-arrow-size;
        height: $breadcrumb-arrow-size;
        margin-left: $breadcrumb-arrow-size;
        border-top: 1px solid;
        border-right: 1px solid;
        transform: rotate(45deg) translateY(-$breadcrumb-arrow-size * 0.5);
      }
    }
  }
  a {
    color: inherit;

    &[aria-current] {
      text-decoration: none;
    }
  }
}
