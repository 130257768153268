/* -List of vacancies
---------------------------------------------------------------------------- */
.vacancies {
  @include section-margin;
}

.vacancies__items {
  @include grid-container;
}

.vacancy-blurb {
  color: $color-gray-dark;
}
.vacancy-blurb__logo {
  @include centered-circle(120px);

  margin: 0 auto 1em;
  background: #fff;
}
.vacancy-blurb__footer {
  margin-top: auto;
  padding-top: 1.5em;
}
.vacancy-blurb__link {
  margin: 1em 0 0;
}

.overlay-link {
  &:hover,
  &:focus {
    ~ .vacancy-blurb__footer a {
      text-decoration: underline !important;
    }
  }
}

@include media-min(rem(500px)) {
  .vacancy-blurb__logo {
    float: right;
    margin: 0 0 0 1em;
  }
  .vacancy-blurb__footer {
    display: flex;
    align-items: flex-end;
  }
  .vacancy-blurb__link {
    margin: 0 0 0 auto;
  }
}
