$wrap-width: rem(1120px);
$wrap-width-thin: rem(880px);
$wrap-width-wide: rem(1360px);

$page-margin: rem(50px);
$page-margin-small: $page-margin * 0.5;

$section-spacing: 60px;
$section-spacing-small: $section-spacing * 0.5;

$sectioning-title-margin: 30px;
$sectioning-title-padding: 10px;

// Consistent border radius, consider using via the `rounded` mixin.
$border-radius: (
  small: 8px,
  medium: 20px,
  large: 40px,
);

// Form elements
// Using em instead of rem so that dimensions adapt to local font size changes
$form-control-base-height: 40px;
$form-control-font-size: 16px;
$form-control-radius: 4px;
$form-field-padding: em(8px, $form-control-font-size);
$form-control-height: em($form-control-base-height, $form-control-font-size);
$button-font-size: 18px;
$button-height: em($form-control-base-height, $button-font-size);

/*-------------------- Common breakpoints --------------------*/
// Most breakpoints should be isolated to what they affect and not be some
// arbitrary values, but global changes like text size may require adjustment
// to details in some instances and should therefore be a defined variable.

$breakpoint-news-grid-columns: rem(1100px);
$breakpoint-news-item-vertical: rem(650px);

$breakpoint-menu-toggle: rem(800px);

// Tighten layout
$breakpoint-grid-tight: rem(750px);
$breakpoint-page-margin-small: rem(750px);
