a:link,
a:visited {
  color: inherit;
}
a:hover,
a:focus {
  text-decoration: none;
}

// 'Stretch' a link to make its nearest positioned container clickable.
@mixin stretched-link {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-overlay-link;
  }
}
// Position a link above its nearest positioned container and hide its text.
@mixin overlay-link {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  text-indent: -999px;
  font-size: 12px;
  z-index: $z-overlay-link;
}
.overlay-link {
  @include overlay-link;
}
.stretched-link {
  @include stretched-link;
}

.skiplink {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: $z-skiplink;

  &:focus {
    @include theme-rule(
      $rule: background,
      $color-name: 'foreground',
      $important: true
    );
    @include theme-rule(
      $rule: color,
      $color-name: 'background',
      $important: true
    );

    display: inline-block;
    padding: 5px 10px !important;
  }
}
