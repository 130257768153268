/* -Vacancy meta data
---------------------------------------------------------------------------- */
.vacancy-meta {
  thead th {
    @include h;
    @include h4;
  }
}

.vacancy-meta--small {
  margin: 0;
}
