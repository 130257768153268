/* -Speaker Detail page
---------------------------------------------------------------------------- */

.speaker-detail {
  @include wrap-wide;
  @include section-margin;

  &__image {
    max-width: rem(300px);

    img {
      @include rounded('medium');
    }
  }

  &__section {
    margin-top: rem(15px);

    h1 {
      margin-top: rem(20px);
    }

    h2 {
      margin: rem(15px) 0px;
    }
  }
}

// Medium screens
@include media-min(rem(1120px)) {
  .speaker-detail {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__image {
      max-width: rem(400px);
    }

    &__section {
      margin: 0 0 0 rem(80px);
      flex-basis: 70%;

      h1 {
        margin: 0;
      }
    }

    &__content {
      @include type-size('sub');
    }
  }
}

@include media-max(rem(1120px)) {
  .speaker-detail {
    max-width: $wrap-width-thin;
  }
}
