/* -Staff block, list of staff items
---------------------------------------------------------------------------- */
.staff-block {
  .block-content {
    @include page-margin-reset;
    margin-left: 0;

    .section-block--center & {
      @include wrap-thin;
    }
  }
}

.staff-block__items {
  @include section-padding-bottom;
  @include grid-container;
  @include grid-pull-bottom;

  .staff {
    @include grid-item('full');
    @include grid-margin-bottom;
  }
}

.staff--vertical {
  .staff-block__items--cols-2 &,
  .staff-block__items--cols-3 & {
    @include media-min(rem(480px)) {
      @include grid-item-size('1 / 2');
    }
  }

  .staff-block__items--cols-3 & {
    @include media-min(rem(1000px)) {
      @include grid-item-size('1 / 3');
    }
  }
}

.staff--horizontal {
  .staff-block__items--cols-2 & {
    @include media-min(rem(950px)) {
      @include grid-item-size('1 / 2');
    }
  }
}
