/* -A single news article
---------------------------------------------------------------------------- */
.news-detail__body {
  @include section-margin;
}

.news-detail__title {
  text-align: center;
  font-size: 26px;

  @include media-min(rem(600px)) {
    font-size: 38px;
  }

  @include media-min(rem(900px)) {
    font-size: 46px;
  }
  @include media-min(rem(1200px)) {
    font-size: 56px;
  }
}

.news-detail__image {
  @include section-margin(0.5);

  text-align: center;

  img {
    @include rounded('medium');
  }
}

.news-detail__meta {
  @include section-margin-top(0.5);
}

.news-detail__content {
  @include type-size('sub');
}
