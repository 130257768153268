/* -General table styles
---------------------------------------------------------------------------- */
$table-border-opacity: 0.25;

.table-wrap {
  overflow-x: auto;
  margin-bottom: $text-spacing;

  table {
    margin-bottom: 0;
  }
}

table {
  @include theme-tint-shade-transparent(
    border,
    $table-border-opacity,
    $value-template: '1px solid {{color}}'
  );

  border-collapse: collapse;
}

th,
td {
  @include theme-tint-shade-transparent(
    border-top,
    $table-border-opacity,
    $value-template: '1px solid {{color}}'
  );
  @include theme-tint-shade-transparent(
    border-bottom,
    $table-border-opacity,
    $value-template: '1px solid {{color}}'
  );

  padding: 10px 15px;
  vertical-align: top;
  font-size: inherit;
}

th {
  text-align: left;
}

.compact-table {
  border: 0;

  th,
  td {
    border: 0;
    padding: 0 10px 0 0;
  }
}
