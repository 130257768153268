/* -Wide image block
-----------------------------------------------------------------------------*/
.image-wide-block__image {
  max-height: 740px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  img {
    max-height: 100%;
    width: 100%;
  }
}
