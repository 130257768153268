/* -Callout block
---------------------------------------------------------------------------- */
.callout-block-wrap {
  @include section-margin;

  // Must have a wrapping column flex container for IE11
  // https://github.com/philipwalton/flexbugs#flexbug-3
  display: flex;
  flex-direction: column;
}

.callout-block {
  @include rounded('large');

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  min-height: 300px;
  background-size: cover;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.4);
}

.callout-block-content {
  @include type-size('sub');

  max-width: 600px;
  text-align: center;
}

.callout-block--color-light {
  color: #fff;
}

@include media-min(rem(500px)) {
  .callout-block {
    min-height: 390px;
  }
}
