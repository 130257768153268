.link-block {
  &:last-child {
    margin-bottom: 0;
  }
}

.link-block--button--light {
  a {
    @include button-white;
  }
}

.link-block--button--dark {
  a {
    @include button-dark;
  }
}
