.speaker-card {
  &__inner {
    position: relative;
  }

  &__image {
    margin-bottom: rem(15px);
  }

  &__tags {
    margin-bottom: 10px;
  }

  &__link {
    text-decoration: none;

    .speaker-card__title-name::after {
      transition: margin-left 0.2s ease;
    }

    &:hover,
    &:focus {
      text-decoration: underline;

      .speaker-card__title-name::after {
        margin-left: rem(10px);
      }
    }
  }

  &__name-container {
    display: flex;
    justify-content: space-between;
  }

  &__job-title {
    margin: {
      bottom: 0px;
    }
  }

  // Speaker's name
  &__title-name {
    margin: rem(10px) 0 0;

    &::after {
      content: ' ';
      display: inline-block;
      margin-right: rem(-20px);
      margin-left: rem(8px);
      width: rem(12px);
      height: rem(12px);
      background-image: svg-url(
        '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="17"><path fill="#FFF" d="M8.9 8.5 0 4.6V0l17.4 8.2a.3.3 0 0 1 0 .6L0 17v-4.6l8.9-3.9z"/></svg>'
      );
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

.speaker-card__image {
  @include rounded('medium');

  overflow: hidden;
}

// Media queries
@include media-min(rem(750px)) {
  .speaker-card {
    &:hover,
    &:focus {
      .speaker-card__title-name::after {
        margin-left: rem(15px);
      }
    }

    &__title-name {
      &::after {
        margin-right: rem(-28px);
        margin-left: rem(10px);
        width: rem(18px);
        height: rem(18px);
      }
    }
  }
}
