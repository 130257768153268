/* -A single staff/person with a horizontal or vertical version
-----------------------------------------------------------------------------*/
.staff__content {
  @include type-size('sub');
}

.staff__email {
  margin: 0 0 5px;
}

.staff--vertical {
  .staff__inner {
    max-width: 400px;
  }
  .staff__image {
    margin-bottom: 25px;

    img {
      @include rounded('medium');
    }
  }
  .staff__job-title {
    margin-bottom: 25px;
  }
}

.staff--horizontal {
  .staff__inner {
    text-align: center;
  }
  .staff__image {
    flex-shrink: 0;
    max-width: 180px;
    margin: 0 auto 15px;
    margin-bottom: 15px;

    img {
      @include rounded('medium');
    }
  }
  .staff__content {
    p {
      margin: 5px 0 0;
    }
  }

  @include media-min(rem(450px)) {
    .staff__inner {
      display: flex;
      align-items: center;
      text-align: left;
    }
    .staff__image {
      width: 35%;
      margin: 0 30px 0 0;
    }
  }
}
