/* -A single company block
---------------------------------------------------------------------------- */
.company {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.company__inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.company__image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 10px;

  img {
    flex-shrink: 0;
  }
}

.company__content {
  margin-top: 10px;
  // Make the content the same height with and without a link
  min-height: 4.5em;
}

.company__name {
  align-self: flex-end;
  margin: 0;
}

.company__link {
  margin-bottom: 0;
  font-size: rem(18px);

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
