/* -A small selection of news articles
---------------------------------------------------------------------------- */
.news-selection {
  @include section-margin(2);
}

.news-selection__items {
  @include grid-container;
  @include grid-pull-bottom;

  > .news-item {
    @include grid-item('full');
    @include grid-margin-bottom;

    @include media-min(rem(600px)) {
      @include grid-item-size('1 / 2');
    }

    @include media-min(rem(1050px)) {
      @include grid-item-size('1 / 3');
    }
  }
}
