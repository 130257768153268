/* -Blurb component - block of image, heading, short text and a link
-----------------------------------------------------------------------------*/
$blurb-padding-horizontal: 25px;
$blurb-padding-vertical: 20px;

.blurb {
  display: flex;
  flex-direction: column;

  .blurb-list--color-dark & {
    color: $color-gray-dark;
  }
  .blurb-list--color-light & {
    color: #fff;
  }
}

.blurb__inner {
  @include rounded('medium');

  position: relative;
  max-width: 640px;
  min-height: 275px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: $blurb-padding-vertical $blurb-padding-horizontal;
  background-size: cover;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-color: $color-purple-light;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);

  @include media-min(rem(800px)) {
    font-size: rem(18px);
  }
}

.blurb__title {
  margin-bottom: 10px;
}
.blurb__text {
  @include type-size('sub');

  p:last-child {
    margin-bottom: 0.5em;
  }
}
.blurb__link {
  margin-top: auto;
  text-align: right;

  p {
    margin: 0;
  }
  a {
    display: inline-block;
    margin: 0 (-$blurb-padding-horizontal + 5px)
      (-$blurb-padding-vertical + 5px) 0;
    padding: 1em ($blurb-padding-horizontal - 5px)
      ($blurb-padding-vertical - 5px) 1em;
    // Match focus outline to box
    border-bottom-right-radius: 15px;
  }
}

.overlay-link {
  &:hover,
  &:focus {
    ~ .blurb__link a {
      text-decoration: underline !important;
    }
  }
}

/*--------------- List of blurbs (blurbs block) ---------------*/

.blurb-list-wrap {
  @include section-margin(2);
}

.blurb-list {
  @include grid-container;
  @include grid-pull-bottom;

  text-align: center;

  .blurb {
    @include grid-item('full');
    @include grid-margin-bottom;

    text-align: left;
  }
}

@include media-min(rem(1000px)) {
  .blurb-list--cols-2,
  .blurb-list--cols-3 {
    text-align: left;

    .blurb {
      @include grid-item-size('1 / 2');
    }
    .blurb__inner {
      min-height: 300px;
    }
  }
}

@include media-min(rem(1400px)) {
  .blurb-list--cols-3 {
    .blurb {
      @include grid-item-size('1 / 3');
    }
    .blurb__inner {
      min-height: 275px;
    }
  }
}
