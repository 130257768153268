/* -Section block
---------------------------------------------------------------------------- */
.section-block {
  @include section-padding(2);

  .block-content,
  .form-block {
    @include page-margin-reset;
    max-width: $wrap-width-thin;
  }
}

.section-block__inner {
  .wrap,
  .wrap-thin,
  .wrap-wide {
    @include wrap-reset;
  }

  > *:first-child {
    margin-top: 0 !important;
  }
  > *:last-child {
    margin-bottom: 0 !important;
  }
}

.section-block__inner--thin {
  @include wrap-thin;
}
.section-block__inner--wide {
  @include wrap-wide;
}

.section-block--left {
  .block-content,
  .form-block {
    margin-left: 0;
    margin-right: 0;
  }
}
.section-block--center {
  .block-content {
    text-align: center;
  }
}

.section-block--brand-gradient {
  background: $color-brand-gradient-diagonal;
}
.section-block--secondary-gradient {
  background: $color-secondary-gradient;
}
.section-block--alt-gradient {
  background: $color-alt-gradient;
}

.section-block--brand-gradient,
.section-block--secondary-gradient,
.section-block--alt-gradient {
  color: #fff;
}

@each $color in ('white') {
  .section-block--#{$color} + .section-block--#{$color} {
    @include section-pull-top(2);
  }
}
