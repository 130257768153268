// Color themes for the site.
//
// All colors are defined in a map where the root keys are the name of the
// themes. A default theme is used for base rules while other themes are
// used beind a class that should be set on the html element in the form
// of `theme-<themeName>` (e.g. theme-dark).
//
// Theme colors are applied with mixins, where the mixins further down in the
// file should be preferred when possible since they're simpler.
//
// In addition to the colors each theme also has a type which affects colors
// that get lightened or darkened (e.g. the tint-shade parameters and mixins).
// Unless reversed by a parameter, a light theme will have colors darkened and
// vice versa.

// Color definitions
// ----------------------------------------------------------------------------

$color-brand-magenta: #de007b;
$color-brand-orange: #ec6b10;
$color-brand-gradient: linear-gradient(
  to right,
  $color-brand-magenta,
  $color-brand-orange
);
$color-brand-gradient-darkened: linear-gradient(
  to right,
  darken($color-brand-magenta, 10%),
  darken($color-brand-orange, 10%)
);
$color-brand-gradient-diagonal: linear-gradient(
  to bottom right,
  rgba($color-brand-magenta, 0.8),
  rgba($color-brand-orange, 0.8)
);

$color-dark: #212121;
$color-gray-dark: #3c3e3e;
$color-purple: #6d5b9f;
$color-purple-light: #e5e0f2;
$color-turquoise: #9fcac0;
$color-blue-gray: #004c5d;
$color-yellow: #ffed00;
$color-red: #e84b30;
$color-brown: #964b00;
$color-blue-light: #87bfff;
$color-pink: #ff87ab;
$color-green: #008000;

$color-secondary-gradient: linear-gradient(
  to bottom right,
  $color-blue-gray,
  $color-turquoise
);
$color-alt-gradient: linear-gradient(
  to bottom right,
  $color-purple,
  $color-turquoise
);

// Status
$color-error: #ed404b;
$color-error-foreground: #d71421;
$color-error-background: #fae8e6;
$color-success: #49ad5a;
$color-success-foreground: #337a3b;
$color-success-background: #e7fae6;
$color-info-foreground: #06608c;
$color-info-background: #d5e9f9;
$color-warning-foreground: #715100;
$color-warning-background: #fffbd5;

// -------------------- Private color definitions -------------------- //

$__color-theme-light-foreground: $color-gray-dark;
$__color-theme-light-background: #fff;

$__color-theme-dark-foreground: #fcfcfc;
$__color-theme-dark-background: $color-dark;

// Color themes
// ----------------------------------------------------------------------------

// Theme color definitions
$default-theme: light;
$themes: (
  light: (
    type: 'light',
    background: $__color-theme-light-background,
    foreground: $__color-theme-light-foreground,
    separator: rgba(0, 0, 0, 0.15),
    form-accent: #317ada,
    // Lightest gray text color on white that meets WCAG AA accessibility
    // requirement. (Approximately #767676 on #fff.)
    lightest-accessible: rgba(0, 0, 0, 0.54),
    // Lightest text color on white that meets WCAG AA accessibility requirement
    // for LARGE TEXT ONLY. Large is defined as 19px+ and bold, or 24px+.
    // (Approximately #949494 on #fff.)
    lightest-accessible-large: rgba(0, 0, 0, 0.42),
    form-field-background: $__color-theme-light-background,
    form-field-foreground: $__color-theme-light-foreground,
    form-field-border: darken($__color-theme-light-background, 20%),
    disabled: #aaa,
    selection-background: #eb5f6a,
    selection-color: #fff,
    footer-background: darken($__color-theme-light-background, 4%),
    consent-dialog: darken($__color-theme-light-background, 5%),
  ),
  dark: (
    type: 'dark',
    background: $__color-theme-dark-background,
    foreground: $__color-theme-dark-foreground,
    separator: rgba(255, 255, 255, 0.15),
    form-accent: #317ada,
    // Darkest gray text color on black that meets WCAG AA accessibility
    // requirement. (Approximately #757575 on #000.)
    lightest-accessible: rgba(255, 255, 255, 0.46),
    // Lightest text color on white that meets WCAG AA accessibility requirement
    // for LARGE TEXT ONLY. Large is defined as 19px+ and bold, or 24px+.
    // (Approximately #5c5c5c on #000.)
    lightest-accessible-large: rgba(255, 255, 255, 0.36),
    form-field-background: darken($__color-theme-dark-background, 5%),
    form-field-foreground: $__color-theme-dark-foreground,
    form-field-border: lighten($__color-theme-dark-background, 20%),
    disabled: #555,
    selection-background: #eb5f6a,
    selection-color: #fff,
    footer-background: $__color-theme-dark-background,
    consent-dialog: lighten($__color-theme-dark-background, 5%),
  ),
);

/// Get a defined theme color.
///
/// @param {string} $theme - The theme name/ID.
/// @param {string} $color - The color name/ID.
/// @param {percentage} $tint-shade-amount [0%] - Amount of tint or shade to
///   apply to the color, depending on the theme.
/// @param {boolean} $tint-shade-reverse [false] - By default a tint/shade
///   value for light theme will darken the color. With $reverse true it will
///   be the opposite.
/// @return {color} The final color.
@function get-theme-color(
  $theme,
  $color,
  $tint-shade-amount: 0%,
  $tint-shade-reverse: false
) {
  $theme-data: map-get-strict($themes, $theme);
  $theme-color: map-get-strict($theme-data, $color);
  @if $tint-shade-amount != 0% {
    $theme-type: map-get-strict($theme-data, 'type');
    @if ($theme-type == 'light' and not $tint-shade-reverse) or
      ($theme-type == 'dark' and $tint-shade-reverse)
    {
      @return darken($theme-color, $tint-shade-amount);
    }
    @return lighten($theme-color, $tint-shade-amount);
  }
  @return $theme-color;
}

/// Get a plain light or dark color depending on the theme type.
///
/// @param {string} $theme - The theme name/ID.
/// @param {boolean} $reverse [false] - By default a light theme results in a
///   dark color. With $reverse true it will be the opposite.
/// @return {color} The resulting color.
@function get-theme-light-dark($theme, $reverse: false) {
  $theme-data: map-get-strict($themes, $theme);
  $theme-type: map-get-strict($theme-data, 'type');
  @if ($theme-type == 'light' and not $reverse) or
    ($theme-type == 'dark' and $reverse)
  {
    @return #222;
  }

  @return #fff;
}

/// Create a rule for every theme.
///
/// @param {string} $rule - The rule name (e.g. border-color).
/// @param {string} $color-name - Name/ID of the color to use for the rule.
/// @param {percentage} $tint-shade-amount [0%] - Amount of tint or shade to
///   apply to the color, depending on the theme.
/// @param {boolean} $tint-shade-reverse [false] - By default a tint/shade
///   value for light theme will darken the color. With $reverse true it will
///   be the opposite.
/// @param {string} $value-template ['{{color}}'] - Template for the rule value,
///   where {{color}} is replaced with the final color. Use for rules where the
///   the value contains more than just the color, e.g. '1px solid {{color}}'
///   for a border shorthand rule.
/// @param {boolean} $important [false] - Use !important.
@mixin theme-rule(
  $rule,
  $color-name,
  $tint-shade-amount: 0%,
  $tint-shade-reverse: false,
  $value-template: '{{color}}',
  $important: false
) {
  $val: unquote(
    str-replace(
      $value-template,
      '{{color}}',
      get-theme-color(
        $default-theme,
        $color-name,
        $tint-shade-amount,
        $tint-shade-reverse
      )
    )
  );
  @if $important {
    #{$rule}: $val !important;
  } @else {
    #{$rule}: $val;
  }

  @each $theme-name, $theme-data in $themes {
    @if $theme-name != $default-theme {
      $val: unquote(
        str-replace(
          $value-template,
          '{{color}}',
          get-theme-color(
            $theme-name,
            $color-name,
            $tint-shade-amount,
            $tint-shade-reverse
          )
        )
      );
      .theme-#{$theme-name} & {
        @if $important {
          #{$rule}: $val !important;
        } @else {
          #{$rule}: $val;
        }
      }
    }
  }
}

/// Create an rgba() rule for every theme.
///
/// @param {string} $rule - The rule name (e.g. border-color).
/// @param {number} $amount - Opacity between 0 and 1.
/// @param {string} $value-template ['{{color}}'] - Template for the rule value,
///   where {{color}} is replaced with the final color. Use for rules where the
///   the value contains more than just the color, e.g. '1px solid {{color}}'
///   for a border shorthand rule.
/// @param {boolean} $reverse [false] - By default a light theme results in a
///   black layer. With $reverse true it will be the opposite.
/// @param {boolean} $important [false] - Use !important.
@mixin theme-tint-shade-transparent(
  $rule,
  $amount,
  $value-template: '{{color}}',
  $reverse: false,
  $important: false
) {
  $light-rule: rgba(0, 0, 0, $amount);
  $dark-rule: rgba(255, 255, 255, $amount);
  @if $reverse {
    $light-rule: rgba(255, 255, 255, $amount);
    $dark-rule: rgba(0, 0, 0, $amount);
  }

  $val: unquote(str-replace($value-template, '{{color}}', $light-rule));
  @if $important {
    #{$rule}: $val !important;
  } @else {
    #{$rule}: $val;
  }

  @each $theme-name, $theme-data in $themes {
    @if $theme-name != $default-theme {
      $theme-type: map-get-strict($theme-data, 'type');
      .theme-#{$theme-name} & {
        @if $theme-type == 'light' {
          $val: unquote(str-replace($value-template, '{{color}}', $light-rule));
        } @else if $theme-type == 'dark' {
          $val: unquote(str-replace($value-template, '{{color}}', $dark-rule));
        }
        @if $important {
          #{$rule}: $val !important;
        } @else {
          #{$rule}: $val;
        }
      }
    }
  }
}

/// Create a plain light/dark color rule for every theme.
///
/// @param {string} $rule - The rule name (e.g. border-color).
/// @param {string} $value-template ['{{color}}'] - Template for the rule value,
///   where {{color}} is replaced with the final color. Use for rules where the
///   the value contains more than just the color, e.g. '1px solid {{color}}'
///   for a border shorthand rule.
/// @param {boolean} $reverse [false] - By default a light theme results in a
///   black layer. With $reverse true it will be the opposite.
/// @param {boolean} $important [false] - Use !important.
@mixin theme-light-dark(
  $rule,
  $value-template: '{{color}}',
  $reverse: false,
  $important: false
) {
  $val: unquote(
    str-replace(
      $value-template,
      '{{color}}',
      get-theme-light-dark($default-theme, $reverse)
    )
  );
  @if $important {
    #{$rule}: $val !important;
  } @else {
    #{$rule}: $val;
  }

  @each $theme-name, $theme-data in $themes {
    @if $theme-name != $default-theme {
      .theme-#{$theme-name} & {
        $val: unquote(
          str-replace(
            $value-template,
            '{{color}}',
            get-theme-light-dark($theme-name, $reverse)
          )
        );
        @if $important {
          #{$rule}: $val !important;
        } @else {
          #{$rule}: $val;
        }
      }
    }
  }
}

// Higher level mixins for common rules
@mixin theme-foreground {
  @include theme-rule(color, 'foreground');
}
@mixin theme-background {
  @include theme-rule(background-color, 'background');
}
@mixin theme-action-foreground {
  @include theme-rule(color, 'action-foreground');
}
@mixin theme-action-background {
  @include theme-rule(background-color, 'action-background');
}
@mixin theme-separator-top {
  @include theme-rule(
    border-top,
    'separator',
    $value-template: '1px solid {{color}}'
  );
}
@mixin theme-separator-bottom {
  @include theme-rule(
    border-bottom,
    'separator',
    $value-template: '1px solid {{color}}'
  );
}
