/* -A single vacancy page view
---------------------------------------------------------------------------- */
.vacancy__intro {
  @include section-padding;
  @include section-margin-bottom;

  background: $color-alt-gradient;
  color: #fff;
  text-align: center;
}

.vacancy__logo {
  @include centered-circle(250px);

  margin: 0 auto;
  background: #fff;
}

@include media-min(rem(1200px)) {
  .vacancy__intro {
    text-align: left;
  }
  .vacancy__intro-content {
    display: flex;
    align-items: center;
  }
  .vacancy__intro-text {
    flex-grow: 1;
    margin: 0;
  }
  .vacancy__logo {
    @include centered-circle-size(350px);
    @include grid-margin-left;

    flex-shrink: 0;
  }
}

.vacancy__content,
.vacancy__footer {
  font-size: rem(18px);
}
.vacancy__footer,
.vacancy__application {
  margin-top: 1.5em;
}
