/* -Main area + sidebar for news lists
---------------------------------------------------------------------------- */
.news-grid__main {
  @include grid-margin-bottom;
}

@include media-min($breakpoint-news-grid-columns) {
  .news-grid {
    @include grid-container;
  }
  .news-grid__main {
    @include grid-item('2 / 3');

    margin-bottom: 0;

    // When there is no extra
    &:last-child {
      @include grid-item('full');
    }
  }
  .news-grid__extra {
    @include grid-item('1 / 3');
  }
}
