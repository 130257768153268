/* -Speaker tags list
---------------------------------------------------------------------------- */
.speaker-tags {
  margin-left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  list-style: none;

  li {
    @include button-small;
  }
}

// Media queries
@include media-min(rem(500px)) {
  .speaker-tags {
    gap: 10px;
  }
}

// Adjustment for speaker card.
@include media-max(rem(500px)) {
  .speaker-card__tags {
    li {
      @include button-tiny;
    }
  }
}
