/* -News blocks and detail meta info
---------------------------------------------------------------------------- */
.news-meta {
  display: flex;
}

.news-meta__tags {
  list-style: none;
  margin: 0 2em 0 0;

  &,
  li {
    display: inline-block;
  }
  li:not(:last-child) {
    margin-right: em(5px);

    &::after {
      content: ', ';
    }
  }
}

.news-meta__date {
  @include theme-rule(color, 'lightest-accessible');

  margin: 0;
  white-space: nowrap;
}
