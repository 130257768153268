/* -The global footer area
-----------------------------------------------------------------------------*/
.site-footer {
  @include section-padding;
  @include theme-separator-top;
  @include theme-rule(background-color, 'footer-background');

  .h-sectioning {
    margin-bottom: 15px;
  }
}

.site-footer__text {
  font-size: rem(18px);

  .logo {
    margin-bottom: 20px;
  }
}

/* ------------------- Menus ------------------- */
$footer-menu-gutter: 50px;

.site-footer__menu,
.site-footer__text {
  // No smaller margin for tight grid
  margin-bottom: $footer-menu-gutter;
}

.site-footer__menu {
  li {
    margin-top: 10px;
    padding-left: $sectioning-title-padding;
    padding-right: $sectioning-title-padding;
  }
  a {
    @include alt-font('semi');

    display: inline-block;
    font-size: rem(18px);
    text-transform: uppercase;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
.footer-menu__icon-item {
  display: inline-flex;
  align-items: center;

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 0.5em;
  }
  &:hover,
  &:focus {
    text-decoration: none;

    .text {
      text-decoration: underline;
    }
  }
}

@include media-min(rem(600px)) {
  .site-footer__nav-groups {
    @include grid-container($footer-menu-gutter, $footer-menu-gutter * 0.5);
  }
  .site-footer__menu {
    @include grid-item('1 / 2', $footer-menu-gutter, $footer-menu-gutter * 0.5);

    flex-shrink: 0;
  }
}
@include media-min(rem(900px)) {
  .site-footer__menu {
    @include grid-item-size('1 / 3');

    a {
      font-size: rem(22px);
    }
  }
}
@include media-min(rem(1100px)) {
  .site-footer__main {
    display: flex;
  }
  .site-footer__nav {
    flex-grow: 1;
    padding-right: $footer-menu-gutter * 2;
  }
  .site-footer__text {
    margin-left: auto;
  }
}

/* ------------------- Partners ------------------- */
.site-footer__partners {
  overflow: hidden;
  margin-left: -30px;

  img {
    float: left;
    margin: 0 0 30px 30px;
  }
  .partner-skovde {
    max-width: 100px;
  }
  .partner-university {
    max-width: 115px;
  }
  .partner-science-park,
  .partner-region,
  .partner-next {
    max-width: 250px;
  }

  // No rem since images aren't affected by font size
  @include media-min(850px) {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-left: -40px;

    img {
      float: none;
      margin: 0 0 0 40px;
    }
    .partner-skovde,
    .partner-university {
      flex-shrink: 0;
    }
    .partner-science-park,
    .partner-region,
    .partner-next {
      flex: 1;
    }
  }
  @include media-min(1000px) {
    margin-left: -50px;

    img {
      margin-left: 50px;
    }
  }
}
