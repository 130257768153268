/* -Key figure component
-----------------------------------------------------------------------------*/
$key-figure-item-column-breakpoint: rem(700px);
$key-figure-gutter: 50px;
$key-figure-gutter-small: $key-figure-gutter * 0.5;

.key-figures-block {
  @include section-margin;

  &.key-figures-block__items--count-1 {
    @include wrap-reset;
    @include wrap-thin;
  }
}
.key-figure-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 465px !important;

  @include media-min($key-figure-item-column-breakpoint) {
    flex-direction: row;
  }
}
.key-figure-item__figure {
  flex-shrink: 0;
  padding: 0 10px;
  min-width: 110px;
  height: 40px;
  line-height: 40px;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  background: linear-gradient(
    -225deg,
    rgba($color-purple, 0.3),
    rgba($color-turquoise, 0.3)
  );

  .section-block--brand-gradient &,
  .section-block--secondary-gradient &,
  .section-block--alt-gradient & {
    background: rgba(255, 255, 255, 0.2);
  }

  @include media-min(rem(500px)) {
    min-width: 120px;
    font-size: 24px;
    height: 45px;
    line-height: 45px;
  }

  @include media-min($key-figure-item-column-breakpoint) {
    padding: 0 20px;
    margin-right: 15px;
    font-size: 28px;
  }
  @include media-min($key-figure-item-column-breakpoint + rem(100px)) {
    min-width: 130px;
    height: 50px;
    line-height: 50px;
    font-size: 30px;
  }
  @include media-min(rem(1100px)) {
    min-width: 160px;
    height: 60px;
    line-height: 60px;
    font-size: 40px;
  }
}
.key-figure-item__text {
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;

  @include media-min($key-figure-item-column-breakpoint) {
    margin: 0;
    text-align: left;
    font-size: 16px;
  }
}

/* -List of key figure components
-----------------------------------------------------------------------------*/

.key-figures-block__items {
  @include grid-container($key-figure-gutter, $key-figure-gutter-small);
  @include grid-pull-bottom($key-figure-gutter, $key-figure-gutter-small);

  justify-content: flex-start;
}

.key-figure-item {
  @include grid-item('1 / 2', $key-figure-gutter, $key-figure-gutter-small);
  @include grid-margin-bottom($key-figure-gutter, $key-figure-gutter-small);

  @include media-min(rem(1150px)) {
    .key-figures-block__items--cols-3 & {
      @include grid-item-size('1 / 3');
    }
  }
}
