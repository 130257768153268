/* -Block for speakers
---------------------------------------------------------------------------- */
$speakers-list-gutter: rem(50px);
$speakers-list-gutter-tight: rem(25px);

.speakers-list {
  @include wrap-wide;
  @include section-margin;
}

.speakers-list__content {
  max-width: $wrap-width-thin;
}

.speakers-container {
  @include section-margin-top(0.5);
  @include grid-container($speakers-list-gutter, $speakers-list-gutter-tight);
  @include grid-pull-bottom($speakers-list-gutter, $speakers-list-gutter-tight);

  .speaker-card {
    @include grid-item(
      '1 / 2',
      $speakers-list-gutter,
      $speakers-list-gutter-tight
    );
    @include grid-margin-bottom($speakers-list-gutter);
  }
}

// Medium screens
@include media-min(rem(850px)) {
  .speakers-container {
    .speaker-card {
      @include grid-item-size('1 / 3');
    }
  }
}

// Large screens
@include media-min(rem(1250px)) {
  .speakers-container {
    .speaker-card {
      @include grid-item-size('1 / 4');
    }
  }
}
