/* -Form block
---------------------------------------------------------------------------- */
.form-block {
  @include wrap-thin;
  @include section-margin;

  select {
    display: block;
    margin-top: 2px;
    padding: 5px 10px;
  }
  /* .form-field-group-fields {
    @include grid-container;
  } */
}

.form-block--contact {
  @include media-min(rem(900px)) {
    .form-field--name,
    .form-field--email,
    .form-field--company {
      @include grid-item-size('1 / 3');
    }
  }
}

.form-block__content {
  margin-bottom: $text-spacing;
}
