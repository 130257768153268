/* -Previous/next pagination
---------------------------------------------------------------------------- */
.pagination {
  @include section-margin;

  display: flex;
  align-items: center;
  width: 100%;

  a:not([href]) {
    @include button-gray-base-colors(!important);

    opacity: 0.4;
  }
}

.pagination__prev,
.pagination__next {
  min-width: rem(130px);
}
.pagination__next {
  text-align: right;
}

.pagination__info {
  @include h;
  @include h3;
  @include theme-rule(color, 'lightest-accessible-large');

  margin: 0 auto;
  white-space: nowrap;
}

@include media-max(rem(600px)) {
  .pagination__prev,
  .pagination__next {
    min-width: 0;

    a {
      @include button-small;
    }
  }
  .pagination__info {
    font-size: rem(20px);
  }
}
