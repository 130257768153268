/* -Icons
-----------------------------------------------------------------------------*/
/* AUTOGENERATED, DO NOT EDIT MANUALLY */

$icon-base-width: 16px;
$icon-base-height: 16px;
.icon {
  width: $icon-base-width;
  height: $icon-base-height;
  flex-grow: 0;
  flex-shrink: 0;
}

$icon-arrow-width: 18px;
$icon-arrow-height: 17px;
.icon--arrow {
  width: $icon-arrow-width;
  height: $icon-arrow-height;
}

$icon-cross-width: 16px;
$icon-cross-height: 16px;
// Size matches base icon size, no rule generated

$icon-play-width: 16px;
$icon-play-height: 16px;
// Size matches base icon size, no rule generated

$icon-facebook-width: 16px;
$icon-facebook-height: 16px;
// Size matches base icon size, no rule generated

$icon-linkedin-width: 16px;
$icon-linkedin-height: 16px;
// Size matches base icon size, no rule generated

$icon-mynewsdesk-width: 16px;
$icon-mynewsdesk-height: 16px;
// Size matches base icon size, no rule generated

$icon-twitter-width: 16px;
$icon-twitter-height: 16px;
// Size matches base icon size, no rule generated