// Clear contained floats
@mixin clearfix {
  &::before,
  &::after {
    content: ' ';
    display: table;
  }
  &::after {
    clear: both;
  }
}

// Screen reader accessible text, from HTML5 Boilerplate
@mixin visuallyhidden {
  clip: rect(0 0 0 0);
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
}
@mixin non-visuallyhidden {
  clip: auto;
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  white-space: inherit;
}

/// Consistent border radius.
///
/// @param {string} [$size] - Radius size name, "small", "medium" or "large".
@mixin rounded($size) {
  border-radius: map-get-strict($border-radius, $size);
}

/// A flex circle with its contents centered.
///
/// @param {number} [$size] - Number with a unit for width and height.
@mixin centered-circle($size) {
  @include centered-circle-size($size);

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}
@mixin centered-circle-size($size) {
  width: $size;
  height: $size;
  padding: round($size * 0.1);
}

// Consistent section and block spacing
@mixin section-margin($times: 1) {
  margin-top: $section-spacing * $times;
  margin-bottom: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    margin-top: $section-spacing-small * $times;
    margin-bottom: $section-spacing-small * $times;
  }
}
@mixin section-margin-top($times: 1) {
  margin-top: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    margin-top: $section-spacing-small * $times;
  }
}
@mixin section-margin-bottom($times: 1) {
  margin-bottom: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    margin-bottom: $section-spacing-small * $times;
  }
}
@mixin section-padding($times: 1) {
  padding-top: $section-spacing * $times;
  padding-bottom: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    padding-top: $section-spacing-small * $times;
    padding-bottom: $section-spacing-small * $times;
  }
}
@mixin section-padding-top($times: 1) {
  padding-top: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    padding-top: $section-spacing-small * $times;
  }
}
@mixin section-padding-bottom($times: 1) {
  padding-bottom: $section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    padding-bottom: $section-spacing-small * $times;
  }
}
@mixin section-pull-top($times: 1) {
  margin-top: -$section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    margin-top: -$section-spacing-small * $times;
  }
}
@mixin section-pull-bottom($times: 1) {
  margin-bottom: -$section-spacing * $times;

  @include media-max($breakpoint-page-margin-small) {
    margin-bottom: -$section-spacing-small * $times;
  }
}

// Media queries
// Defaults to width, since it's far more common than height.
@mixin media-max($max) {
  @media screen and (max-width: $max) {
    @content;
  }
}
@mixin media-min($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}
@mixin media-between($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin media-max-height($max) {
  @media screen and (max-height: $max) {
    @content;
  }
}
@mixin media-min-height($min) {
  @media screen and (min-height: $min) {
    @content;
  }
}
@mixin media-between-height($min, $max) {
  @media screen and (min-height: $min) and (max-height: $max) {
    @content;
  }
}
