/* -Link with an arrow icon
---------------------------------------------------------------------------- */
a.arrow-link {
  @include alt-font('semi');

  display: inline-block;
  text-decoration: none !important;
  text-transform: uppercase;
  color: inherit;
  font-size: rem(18px);

  &:hover,
  &:focus {
    .text {
      text-decoration: underline;
    }
  }
  .icon--arrow {
    position: relative;
    top: -1px;

    &:first-child {
      margin-right: 0.5em;
      transform: rotate(180deg);
    }
    &:last-child {
      margin-left: 0.5em;
    }
  }
  @include media-min(rem(600px)) {
    font-size: rem(22px);
  }
}
