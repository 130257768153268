/* -Search page
---------------------------------------------------------------------------- */
.search-page__content {
  @include section-margin;
}

.search-page__header {
  @include section-margin-bottom;
}

.search-page__results {
  min-height: 30vh;
}
.search-page__results-group {
  @include section-margin-bottom;
}

.search-page__empty {
  font-size: rem(20px);
}

.search-page__results-list {
  @include grid-container;
  @include grid-pull-bottom;
}

/* ------------------- Staff results ------------------- */
.search-page__staff-item {
  @include grid-item('full');
  @include grid-margin-bottom;

  @include media-min(rem(950px)) {
    @include grid-item-size('1 / 2');
  }
  @include media-min(rem(1500px)) {
    @include grid-item-size('1 / 3');
  }
  @include media-min(rem(2000px)) {
    @include grid-item-size('1 / 4');
  }
}

/* ------------------- Company results ------------------- */
.search-page__company-item {
  @include grid-item('full');
  @include grid-margin-bottom;

  @include media-min(rem(550px)) {
    @include grid-item-size('1 / 2');
  }
  @include media-min(rem(900px)) {
    @include grid-item-size('1 / 3');
  }
  @include media-min(rem(1300px)) {
    @include grid-item-size('1 / 4');
  }
  @include media-min(rem(1800px)) {
    @include grid-item-size('1 / 5');
  }
}

/* ------------------- Page results ------------------- */
.search-page__page-item {
  @include grid-item('full');
  @include section-margin-bottom;

  @include media-min(rem(600px)) {
    @include grid-item-size('1 / 2');
  }
  @include media-min(rem(1000px)) {
    @include grid-item-size('1 / 3');
  }
  @include media-min(rem(1400px)) {
    @include grid-item-size('1 / 4');
  }
  @include media-min(rem(1900px)) {
    @include grid-item-size('1 / 5');
  }
}
