/* -Aria tabs
---------------------------------------------------------------------------- */

// Program schedule
$program-schedule-item-date-radius: 5px;

[role='tablist'] {
  display: flex;
  margin-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;
}
// Force tabs to be buttons
button[role='tab'] {
  @include theme-rule(
    border,
    'foreground',
    $value-template: '1px solid {{color}}'
  );
  @include theme-rule(color, 'foreground');

  height: 2em;
  line-height: 2em;
  padding: 1px 1em 0;
  border-radius: 0;
  background: transparent;
  font-size: rem(14px);
  letter-spacing: 0.5px;

  & + & {
    border-left-width: 0;
  }
  &:hover,
  &:focus,
  &[aria-selected='true'] {
    @include theme-rule(background, 'foreground');
    @include theme-rule(color, 'background');
  }
  &:first-child {
    border-top-left-radius: $program-schedule-item-date-radius;
    border-bottom-left-radius: $program-schedule-item-date-radius;
  }
  &:last-child {
    border-top-right-radius: $program-schedule-item-date-radius;
    border-bottom-right-radius: $program-schedule-item-date-radius;
  }
}
