/* -News blocks
---------------------------------------------------------------------------- */
$news-item-image-bottom-margin: 1em;

.news-item {
  @include type-size('sub');
}

.news-item__image {
  @include rounded('medium');

  display: block;
  overflow: hidden;
  transition: opacity 0.15s ease;

  &:hover,
  &:focus {
    opacity: 0.9;
  }
  img {
    width: 100%;
  }
}

.news-item__excerpt {
  margin-bottom: 0.75em;
}

/* ------------------- Horizontal version only ------------------- */
.news-item--horizontal {
  display: flex;
  align-items: flex-start;

  .news-item__image {
    flex: 0 0 auto;
    width: 35%;
    margin-right: 40px;
  }
  .news-item__body {
    flex-grow: 1;
  }
}
.news-item--reversed {
  flex-direction: row-reverse;

  .news-item__image {
    margin-left: 40px;
    margin-right: 0;
  }
}

@include media-max($breakpoint-news-item-vertical) {
  .news-item--horizontal {
    display: block;

    .news-item__image {
      width: auto;
      margin: 0 0 $news-item-image-bottom-margin;
    }
  }
}

/* ------------------- Vertical version only ------------------- */
.news-item--vertical {
  .news-item__image {
    margin-bottom: $news-item-image-bottom-margin;
  }
}
