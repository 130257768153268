/* -Full program schedule
-----------------------------------------------------------------------------*/
// Darkest gray text color on black that meets the WCAG AA accessibility
// requirement. (Approximately #757575 on #000.)
$color-darkest-accessible-on-dark: rgba(255, 255, 255, 0.46);
// Darkest gray text color on black that meets the WCAG AA accessibility
// requirement for LARGE TEXT ONLY. Large is defined as 19px+ and bold,
// or 24px+. (Approximately #5c5c5c on #000.)
$color-darkest-accessible-on-dark-large: rgba(255, 255, 255, 0.36);

/* -Program schedule item
-----------------------------------------------------------------------------*/
.block-schedule {
  @include section-margin;
}

.program-schedule-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  [role='tablist'] {
    margin-bottom: 0;
  }
}

.program-schedule-item__title {
  margin-bottom: 0;

  > span:first-child {
    margin-right: 15px;
  }
}

.program-schedule-item__description {
  margin: 0.5rem 0 1rem;
  font-size: rem(13px);
}

.program-schedule-item__day {
  width: 100%;
  border-collapse: collapse;
  border: none !important;

  th,
  td {
    padding: 1rem 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    @include media-max(rem(750px) - rem(1px)) {
      padding: 0.75rem 8px;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

/* -Program event row
-----------------------------------------------------------------------------*/
.program-event-row {
  font-size: rem(13px);

  &__location {
    margin-top: rem(4px);
    padding: rem(2px) rem(6px);
    text-align: center;
    border-radius: rem(30px);
    font-size: rem(12px);
    border: 2px solid;
  }

  &__location-color {
    &--magenta {
      border-color: $color-brand-magenta;
    }
    &--orange {
      border-color: $color-brand-orange;
    }
    &--white {
      border-color: #fff;
    }
    &--blue {
      border-color: #317ada;
    }
    &--purple {
      border-color: $color-purple;
    }
    &--yellow {
      border-color: $color-yellow;
    }
    &--turquoise {
      border-color: darken($color-turquoise, 25%);
    }
    &--red {
      border-color: darken($color-red, 15%);
    }
    &--green {
      border-color: $color-green;
    }
    &--pink {
      border-color: $color-pink;
    }
    &--brown {
      border-color: $color-brown;
    }
    &--blue-light {
      border-color: $color-blue-light;
    }
  }
}

.program-event-row__time {
  width: 1em;
  padding-right: 10px;
  white-space: nowrap;
  text-align: center;

  .program-schedule-item--multi-stream & {
    text-align: center;
  }
  .stream-button {
    min-width: 75%;
    margin-top: 2px;
  }
}

.program-event-row__content {
  .live-now {
    margin-left: 10px;
    color: $color-darkest-accessible-on-dark;
  }
}

.program-event-row__link {
  padding: 0 5px 0 10px !important;
  text-align: right;

  a {
    display: inline-flex;
    align-items: center;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    text-decoration: none;
    white-space: nowrap;

    .text {
      display: inline-block;
      min-width: 5rem;
      max-width: 7rem;
      white-space: normal;
    }
    &:hover,
    &:focus {
      .text {
        text-decoration: underline;
      }
    }
  }
}

.program-event-row--passed {
  color: $color-darkest-accessible-on-dark;
}
.program-event-row--highlight {
  th,
  td {
    background-color: rgb(255, 255, 255, 0.1);
  }
  .program-event-row__title {
    font-weight: $font-weight-bold;
  }
}

.program-schedule-item__update {
  text-align: right;
}

@include media-min(rem(750px)) {
  .program-event-row__title {
    font-size: 1rem;
    font-weight: $font-weight-semi;
  }
  .program-event-row__link {
    a .text {
      min-width: 7rem;
      max-width: 12rem;
    }
  }
  .program-event-row__link--short {
    a .text {
      min-width: 0;
      white-space: nowrap;
    }
  }
}
