@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@mixin spinner-color($color) {
  border-top-color: $color;
  border-right-color: $color;
  border-bottom-color: $color;
}

.spinner {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 0.9em;
  height: 0.9em;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  border-color: transparent;
  vertical-align: middle;
  transform: rotate(0deg);
  animation: spin 0.5s linear 0s infinite;
}

.spinner--dark {
  @include spinner-color(rgba(0, 0, 0, 0.5));
}
.spinner--light {
  @include spinner-color(rgba(255, 255, 255, 0.5));
}

.spinner-text {
  margin-right: 0.5em;
  font-style: italic;
}
