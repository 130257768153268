/* -A 'view all' link at the bottom of news lists
---------------------------------------------------------------------------- */
.news-view-all {
  @include theme-separator-top;

  margin: $sectioning-title-margin 0 0;
  padding-top: 10px;
  font-size: rem(18px);
  text-align: right;
}
