/* -Buttons
-----------------------------------------------------------------------------*/

/*---------- Mixins ----------*/
@mixin button-base {
  @include rounded('small');
  @include alt-font('semi');

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px 8px;
  border: 0;
  font-size: rem($button-font-size);
  line-height: 1.25;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  text-shadow: none;
  vertical-align: middle;
  box-shadow: none;
  transition: background-color 0.15s ease-out;

  &:focus {
    @include theme-rule(
      outline,
      'foreground',
      $value-template: '1px dotted {{color}}'
    );
  }
}
@mixin button-hover-focus {
  &:not([disabled]) {
    &:hover,
    &:focus {
      @content;
    }
  }
}

@mixin button-primary-base-colors($important: null) {
  background-color: $color-brand-magenta $important;
  background-image: $color-brand-gradient $important;
  color: #fff $important;
}
@mixin button-primary-active-colors {
  background-image: $color-brand-gradient-darkened;
}
@mixin button-primary-colors {
  @include button-primary-base-colors;

  @include button-hover-focus {
    @include button-primary-active-colors;
  }
}
@mixin button-primary {
  @include button-base;
  @include button-primary-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin button-secondary-base-colors($important: null) {
  background-color: $color-purple-light $important;
  color: $color-purple $important;
}
@mixin button-secondary-active-colors {
  background-color: darken($color-purple-light, 10%);
}
@mixin button-secondary-colors {
  @include button-secondary-base-colors;

  @include button-hover-focus {
    @include button-secondary-active-colors;
  }
}
@mixin button-secondary {
  @include button-base;
  @include button-secondary-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin button-secondary-inverted-base-colors($important: null) {
  background-color: $color-purple $important;
  color: #fff $important;
}
@mixin button-secondary-inverted-active-colors {
  background-color: lighten($color-purple, 10%);
}
@mixin button-secondary-inverted-colors {
  @include button-secondary-inverted-base-colors;

  @include button-hover-focus {
    @include button-secondary-inverted-active-colors;
  }
}
@mixin button-secondary-inverted {
  @include button-base;
  @include button-secondary-inverted-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin button-gray-base-colors($important: null) {
  @include theme-rule(
    background-color,
    'background',
    $tint-shade-amount: 12%,
    $important: $important
  );
  @include theme-foreground;
}
@mixin button-gray-active-colors {
  @include theme-rule(background-color, 'background', $tint-shade-amount: 25%);
}
@mixin button-gray-colors {
  @include button-gray-base-colors;

  @include button-hover-focus {
    @include button-gray-active-colors;
  }
}
@mixin button-gray {
  @include button-base;
  @include button-gray-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin button-white-base-colors($important: null) {
  background: #fff $important;
  color: $color-gray-dark $important;
}
@mixin button-white-active-colors {
  background: darken(#fff, 15%);
}
@mixin button-white-colors {
  @include button-white-base-colors;

  @include button-hover-focus {
    @include button-white-active-colors;
  }
}
@mixin button-white {
  @include button-base;
  @include button-white-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin button-dark-base-colors($important: null) {
  background: $color-dark $important;
  color: #fff;
}
@mixin button-dark-active-colors {
  background: lighten($color-dark, 15%);
}
@mixin button-dark-colors {
  @include button-dark-base-colors;

  @include button-hover-focus {
    @include button-dark-active-colors;
  }
}
@mixin button-dark {
  @include button-base;
  @include button-dark-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin button-gradient-foreground-color {
  color: #fff;
  text-shadow: 1px 1px rgba(#000, 0.4);
}

@mixin button-blue-gradient-base-colors($important: null) {
  @include button-gradient-foreground-color;

  background: linear-gradient(
    to right,
    $color-blue-gray,
    lighten($color-blue-gray, 15%)
  );
}
@mixin button-blue-gradient-colors {
  @include button-blue-gradient-base-colors;
}
@mixin button-blue-gradient {
  @include button-base;
  @include button-blue-gradient-colors;
}

@mixin button-purple-gradient-base-colors($important: null) {
  @include button-gradient-foreground-color;

  background: linear-gradient(
    to right,
    darken($color-purple, 20%),
    lighten($color-purple, 10%)
  );
}
@mixin button-purple-gradient-colors {
  @include button-purple-gradient-base-colors;
}
@mixin button-purple-gradient {
  @include button-base;
  @include button-purple-gradient-colors;
}

@mixin button-magenta-gradient-base-colors($important: null) {
  @include button-gradient-foreground-color;

  background: linear-gradient(
    to right,
    darken($color-brand-magenta, 25%),
    lighten($color-brand-magenta, 10%)
  );
}
@mixin button-magenta-gradient-colors {
  @include button-magenta-gradient-base-colors;
}
@mixin button-magenta-gradient {
  @include button-base;
  @include button-magenta-gradient-colors;
}

@mixin button-orange-gradient-base-colors($important: null) {
  @include button-gradient-foreground-color;

  background: linear-gradient(
    to right,
    darken($color-brand-orange, 20%),
    lighten($color-brand-orange, 10%)
  );
}
@mixin button-orange-gradient-colors {
  @include button-orange-gradient-base-colors;
}
@mixin button-orange-gradient {
  @include button-base;
  @include button-orange-gradient-colors;
}

@mixin button-blue-magenta-gradient-base-colors($important: null) {
  @include button-gradient-foreground-color;

  background: linear-gradient(to right, $color-blue-gray, $color-brand-magenta);
}
@mixin button-blue-magenta-gradient-colors {
  @include button-blue-magenta-gradient-base-colors;
}
@mixin button-blue-magenta-gradient {
  @include button-base;
  @include button-blue-magenta-gradient-colors;
}

@mixin button-brown-gradient-base-colors($important: null) {
  @include button-gradient-foreground-color;

  background: linear-gradient(
    to right,
    darken($color-brown, 12%),
    $color-brown
  );
}
@mixin button-brown-gradient-colors {
  @include button-brown-gradient-base-colors;
}
@mixin button-brown-gradient {
  @include button-base;
  @include button-brown-gradient-colors;
}

@mixin button-green-gradient-base-colors($important: null) {
  @include button-gradient-foreground-color;

  background: linear-gradient(
    to right,
    darken($color-green, 12%),
    $color-green
  );
}
@mixin button-green-gradient-colors {
  @include button-green-gradient-base-colors;
}
@mixin button-green-gradient {
  @include button-base;
  @include button-green-gradient-colors;
}

@mixin button-purple-orange-gradient-base-colors($important: null) {
  @include button-gradient-foreground-color;

  background: linear-gradient(to right, $color-purple, $color-brand-orange);
}
@mixin button-purple-orange-gradient-colors {
  @include button-purple-orange-gradient-base-colors;
}
@mixin button-purple-orange-gradient {
  @include button-base;
  @include button-purple-orange-gradient-colors;
}

@mixin button-green-blue-gradient-base-colors($important: null) {
  @include button-gradient-foreground-color;

  background: linear-gradient(to right, $color-green, $color-blue-gray);
}
@mixin button-green-blue-gradient-colors {
  @include button-green-blue-gradient-base-colors;
}
@mixin button-green-blue-gradient {
  @include button-base;
  @include button-green-blue-gradient-colors;
}

@mixin button-brown-blue-gradient-base-colors($important: null) {
  @include button-gradient-foreground-color;

  background: linear-gradient(to right, $color-brown 20%, $color-blue-gray);
}
@mixin button-brown-blue-gradient-colors {
  @include button-brown-blue-gradient-base-colors;
}
@mixin button-brown-blue-gradient {
  @include button-base;
  @include button-brown-blue-gradient-colors;
}

@mixin button-brown-purple-gradient-base-colors($important: null) {
  @include button-gradient-foreground-color;

  background: linear-gradient(to right, $color-brown, $color-purple);
}
@mixin button-brown-purple-gradient-colors {
  @include button-brown-purple-gradient-base-colors;
}
@mixin button-brown-purple-gradient {
  @include button-base;
  @include button-brown-purple-gradient-colors;
}

@mixin button-magenta-turquoise-gradient-base-colors($important: null) {
  @include button-gradient-foreground-color;

  background: linear-gradient(
    to right,
    $color-brand-magenta,
    darken($color-turquoise, 10%)
  );
}
@mixin button-magenta-turquoise-gradient-colors {
  @include button-magenta-turquoise-gradient-base-colors;
}
@mixin button-magenta-turquoise-gradient {
  @include button-base;
  @include button-magenta-turquoise-gradient-colors;
}

@mixin button-magenta-purple-gradient-base-colors($important: null) {
  @include button-gradient-foreground-color;

  background: linear-gradient(to right, $color-brand-magenta, $color-purple);
}
@mixin button-magenta-purple-gradient-colors {
  @include button-magenta-purple-gradient-base-colors;
}
@mixin button-magenta-purple-gradient {
  @include button-base;
  @include button-magenta-purple-gradient-colors;
}

@mixin button-purple-turquoise-gradient-base-colors($important: null) {
  @include button-gradient-foreground-color;

  background: linear-gradient(
    to right,
    $color-purple,
    darken($color-turquoise, 10%)
  );
}
@mixin button-purple-turquoise-gradient-colors {
  @include button-purple-turquoise-gradient-base-colors;
}
@mixin button-purple-turquoise-gradient {
  @include button-base;
  @include button-purple-turquoise-gradient-colors;
}

@mixin button-small {
  padding: 4px 8px 3px;
  font-size: rem(14px);
  border-radius: 6px;
}

@mixin button-tiny {
  padding: 3px 6px 2px;
  font-size: rem(12px);
  border-radius: 4px;
}

@mixin button-large {
  padding: 12px 20px 10px !important;
  font-size: rem(22px) !important;
}

/*---------- Standard button classes ----------*/
.btn,
a.btn,
button,
input[type='submit'],
input[type='button'],
input[type='reset'] {
  @include button-primary;

  &[disabled] {
    cursor: default;
  }
  > span {
    vertical-align: middle;
  }
  .icon,
  .spinner {
    &:first-child {
      margin-right: 0.5em;
    }
    &:last-child {
      margin-left: 0.5em;
    }
    &:only-child {
      margin-left: 0;
      margin-right: 0;
      vertical-align: top;
    }
  }
}

a.btn-secondary,
.btn-secondary {
  @include button-secondary;
}

a.btn-gray,
.btn-gray {
  @include button-gray;
}

a.btn-white,
.btn-white {
  @include button-white;
}

a.btn-dark,
.btn-dark {
  @include button-white;
}

a.btn-brand-gradient,
.btn-brand-gradient {
  @include button-primary();
}

a.btn-gradient-one,
.btn-gradient-one {
  @include button-purple-gradient();
}
a.btn-gradient-two,
.btn-gradient-two {
  @include button-blue-gradient();
}
a.btn-gradient-three,
.btn-gradient-three {
  @include button-magenta-gradient();
}
a.btn-gradient-four,
.btn-gradient-four {
  @include button-orange-gradient();
}
a.btn-gradient-five,
.btn-gradient-five {
  @include button-purple-turquoise-gradient();
}
a.btn-gradient-six,
.btn-gradient-six {
  @include button-magenta-purple-gradient();
}
a.btn-gradient-seven,
.btn-gradient-seven {
  @include button-blue-magenta-gradient();
}
a.btn-gradient-eight,
.btn-gradient-eight {
  @include button-green-gradient();
}
a.btn-gradient-nine,
.btn-gradient-nine {
  @include button-brown-gradient();
}
a.btn-gradient-ten,
.btn-gradient-ten {
  @include button-purple-orange-gradient();
}
a.btn-gradient-eleven,
.btn-gradient-eleven {
  @include button-green-blue-gradient();
}
a.btn-gradient-twelve,
.btn-gradient-twelve {
  @include button-brown-blue-gradient();
}
a.btn-gradient-thirteen,
.btn-gradient-thirteen {
  @include button-brown-purple-gradient();
}
a.btn-gradient-fourteen,
.btn-gradient-fourteen {
  @include button-magenta-turquoise-gradient();
}

.btn--small {
  @include button-small;
}
.btn--large {
  @include button-large;
}

// ----- Special use case buttons -----

// Semantically a button but doesn't have any styles to indicate it
@mixin btn-neutral {
  height: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  background: transparent !important;
  color: inherit !important;
}
// .btn-neutral {
//   @include btn-neutral;
//
//   .no-js & {
//     cursor: default;
//   }
// }

// Button that looks like a regular link
@mixin btn-plain {
  @include btn-neutral;

  text-decoration: underline;
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.btn-plain {
  @include btn-plain;

  .no-js & {
    cursor: default;
  }
}
